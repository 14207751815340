import { FC, ReactNode, useEffect } from "react";
import Image from "next/image";
import Head from "next/head";

type Props = {
  children: ReactNode;
  bg: string;
  src: string;
  bgColor: string;
};

const AuthLayout: FC<Props> = ({ children, bg, src, bgColor }) => {
  useEffect(() => {
    document.body.classList.add("flex");
    document.body.classList.add("h-full");
    document.body.classList.add("flex-col");
  });

  const color = {
    primary: "bg-primary-900",
    purple: "bg-purple-900",
  } as any;

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <div className={`relative flex min-h-screen justify-center px-0`}>
        <div
          className={`w-full relative z-10 flex flex-1 flex-col bg-blur-backoffice bg-bottom bg-no-repeat bg-cover py-10 px-4.5 justify-center md:-mt-20 md:flex-none md:px-28`}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
